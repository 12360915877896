import React from "react";
import { Container } from "react-bootstrap";

const PrivacyComponent = () => {
  return (
    <div className="dark-background">
      <Container className="py-5 text-white">
        <h3>POLÍTICA DE PRIVADESA</h3>
        <p>
          La present Política de Privadesa estableix els termes en què
          LleidaHack usa i protegeix la informació que és proporcionada pels
          seus usuaris al moment d'utilitzar el seu lloc web. Aquesta companyia
          està compromesa amb la seguretat de les dades dels seus usuaris. Quan
          li demanem omplir els camps d'informació personal amb la qual vostè
          pugui ser identificat, ho fem assegurant que només s'emprarà d'acord
          amb els termes d'aquest document. No obstant això, aquesta Política de
          Privadesa pot canviar amb el temps o ser actualitzada pel que li
          recomanem i emfatitzem revisar contínuament aquesta pàgina per a
          assegurar-se que està d'acord amb aquests canvis.
        </p>

        <h4>Informació que és recollida</h4>
        <p>
          El nostre lloc web podrà recollir informació personal per exemple:
          Nom, informació de contacte com la seva adreça de correu electrònic i
          informació demogràfica.
        </p>

        <h4>Ús de la informació recollida</h4>
        <p>
          El nostre lloc web fa servir la informació amb la finalitat de
          proporcionar el millor servei possible, particularment per a mantenir
          un registre d'usuaris, de comandes en cas que aplicació, i millorar
          els nostres productes i serveis. És possible que siguin enviats
          correus electrònics periòdicament a través del nostre lloc amb ofertes
          especials, nous productes i una altra informació publicitària que
          considerem rellevant per a vostè o que pugui brindar-li algun
          benefici, aquests correus electrònics seran enviats a l'adreça que
          vostè proporcioni i podran ser cancel·lats en qualsevol moment.
        </p>

        <p>
          LleidaHack està altament compromès per a complir amb el compromís de
          mantenir la seva informació segura. Usem els sistemes més avançats i
          els actualitzem constantment per a assegurar-nos que no existeixi cap
          accés no autoritzat.
        </p>

        <h4>Cookies</h4>
        <p>
          Una cookie es refereix a un fitxer que és enviat amb la finalitat de
          sol·licitar permís per a emmagatzemar-se en el seu ordinador, en
          acceptar aquest fitxer es crea i la cookie serveix llavors per a tenir
          informació respecte al trànsit web, i també facilita les futures
          visites a una web recurrent. Una altra funció que tenen les cookies és
          que amb elles les webs poden reconèixer-te individualment i, per tant,
          brindar-te el millor servei personalitzat del seu web.
        </p>
        <p>
          El nostre lloc web empra les cookies per a poder identificar les
          pàgines que són visitades i la seva freqüència. Aquesta informació és
          usada únicament per a anàlisi estadística i després la informació
          s'elimina de manera permanent. Vostè pot eliminar les cookies en
          qualsevol moment des del seu ordinador. No obstant això, les cookies
          ajuden a proporcionar un millor servei dels llocs web, estàs no donen
          accés a informació del seu ordinador ni de vostè, a menys que vostè
          així ho vulgui i la proporcioni directament. Vostè pot acceptar o
          negar l'ús de cookies, no obstant això, la majoria dels navegadors
          accepten cookies automàticament perquè serveix per a tenir un millor
          servei web. També vostè pot canviar la configuració del seu ordinador
          per a declinar les cookies. Si es declinen és possible que no pugui
          utilitzar alguns dels nostres serveis.
        </p>

        <h4>Enllaços a tercers</h4>
        <p>
          Aquest lloc web pot contenir enllaços a altres llocs que poguessin ser
          del seu interès. Una vegada que vostè faci clic en aquests enllaços i
          abandoni la nostra pàgina, ja no tenim control sobre el lloc al qual
          és redirigit i, per tant, no som responsables dels termes o privacitat
          ni de la protecció de les seves dades en aquests altres llocs tercers.
          Aquests llocs estan subjectes a les seves pròpies polítiques de
          privadesa per la qual cosa és recomanable que els consulti per a
          confirmar que vostè està d'acord amb aquestes.
        </p>

        <h4>Control de la seva informació personal</h4>
        <p>
          En qualsevol moment vostè pot restringir la recopilació o l'ús de la
          informació personal que és proporcionada al nostre lloc web. Cada
          vegada que se li sol·liciti emplenar un formulari, com el d'alta
          d'usuari, pot marcar o desmarcar l'opció de rebre informació per
          correu electrònic. En cas que hagi marcat l'opció de rebre el nostre
          butlletí o publicitat vostè pot cancel·lar-la en qualsevol moment.
        </p>

        <p>
          Aquesta companyia no vendrà, cedirà ni distribuirà la informació
          personal que és recopilada sense el seu consentiment, tret que sigui
          requerit per un jutge amb un ordre judicial. LleidaHack es reserva el
          dret de canviar els termes de la present Política de Privacitat en
          qualsevol moment.
        </p>
      </Container>
    </div>
  );
};

export default PrivacyComponent;
